var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-8 mt-15", attrs: { id: "login-page" } },
    [
      !this.$store.state.authenticate.SignUpVisible
        ? _c(
            "v-card",
            {
              staticClass: "mt-8  mx-auto",
              attrs: { elevation: "0", width: "389" },
              on: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.enterKeyPressed($event)
                },
              },
            },
            [
              _c("v-card-title", [
                _c("h3", { staticClass: "mx-auto mt-0 mb-0" }, [
                  _vm._v(_vm._s(_vm.$t("Login"))),
                ]),
                _c(
                  "span",
                  { staticClass: "mx-auto" },
                  [
                    _vm._v(_vm._s(_vm.$t("DontHaveAnAccount")) + " "),
                    _c(
                      "router-link",
                      {
                        staticClass: "text-none ml-1",
                        attrs: { color: "primary", to: { name: "Register" } },
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("SignUp")) + " ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "v-window",
                {
                  model: {
                    value: this.$store.state.authenticate.PageLoginStep,
                    callback: function ($$v) {
                      _vm.$set(
                        this.$store.state.authenticate,
                        "PageLoginStep",
                        $$v
                      )
                    },
                    expression: "this.$store.state.authenticate.PageLoginStep",
                  },
                },
                [
                  _c(
                    "v-window-item",
                    { attrs: { value: 1 } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pt-0 pb-0" },
                        [
                          _c(
                            "v-container",
                            [
                              _c(
                                "v-form",
                                { ref: "form", attrs: { id: "login-form" } },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "(###) ###-####",
                                            expression: "'(###) ###-####'",
                                          },
                                        ],
                                        attrs: {
                                          type: "Phone",
                                          outlined: "",
                                          placeholder: _vm.$t(
                                            "PlaceholderPhoneNumber"
                                          ),
                                          rules: [
                                            function (v) {
                                              return (
                                                !!v ||
                                                _vm.$t("PhoneRequiredAlert")
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.loginPhone,
                                          callback: function ($$v) {
                                            _vm.loginPhone = $$v
                                          },
                                          expression: "loginPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        color:
                                          "u-background-primary white--text",
                                        loading:
                                          this.$store.state.authenticate
                                            .ProcessingRequest,
                                        form: "login-form",
                                      },
                                      on: { click: _vm.signUp },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Submit")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    { attrs: { value: 2 } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pt-0" },
                        [
                          _c(
                            "v-container",
                            { staticClass: "pt-0" },
                            [
                              _c(
                                "v-form",
                                { ref: "verificationForm" },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { color: "#2196F3" } },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "mdi mdi-information-outline",
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "WeHaveSentYouAConfirmationCodeToYourPhoneNumber"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("v-text-field", {
                                        ref: "verificationCode",
                                        attrs: {
                                          placeholder:
                                            _vm.$t("ConfirmationCode"),
                                          outlined: "",
                                          rules: [
                                            function (v) {
                                              return (
                                                !!v || _vm.$t("CodeRequired")
                                              )
                                            },
                                          ],
                                        },
                                        model: {
                                          value: _vm.loginCode,
                                          callback: function ($$v) {
                                            _vm.loginCode = $$v
                                          },
                                          expression: "loginCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        block: "",
                                        color:
                                          "u-background-primary white--text",
                                        form: "login-form",
                                      },
                                      on: { click: _vm.verify },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Submit")))]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-card-actions"),
            ],
            1
          )
        : _vm._e(),
      _c("vue-snotify"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }